import React from "react";

import "./assets/css/LastUpdated.css";

function LastUpdated(props) {
    const { updated } = props;

    return (
        <div>
            { updated && 
                <div className="LastUpdated">
                    Last updated {new Intl.DateTimeFormat('en-GB', {
                        timeStyle: 'short'
                    }).format(updated)}
                </div>
            }
        </div>
    )
}

export default LastUpdated;
