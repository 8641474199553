import React from "react";
import axios from "axios";

import './assets/css/App.css';

import Grid from "@material-ui/core/Grid";
import Clock from "./Clock";
import LineStatusTable from "./LineStatusTable";
import LastUpdated from "./LastUpdated";

function App() {
    const [ lineStatuses, setLineStatuses ] = React.useState(JSON.parse(localStorage.getItem('lineCache')) || []);
    const [ lastUpdated, setLastUpdated ] = React.useState(new Date(localStorage.getItem('lastUpdated')) || null);

    window.title = 'Locale Look - TfL Service Updates';

    React.useEffect(() => {
        getLineStatuses();

        const timer = setInterval(() => {
            getLineStatuses();
        }, 120 * 1000);

        return () => {
            clearInterval(timer);
        }
    }, []);

    const getLineStatuses = () => {
        axios.get('https://api.tfl.gov.uk/line/mode/tube,dlr,tflrail,tram,overground/status')
             .then((response) => {
                 let newLineStatus = [];

                 response.data.forEach((line) => {
                     let newLine = {
                         'name': line.name
                     };

                     if(line.lineStatuses.length > 1) {
                         let lineStates = [];
                         line.lineStatuses.forEach((s) => {
                             if(lineStates.length < 2 && lineStates.indexOf(s.statusSeverityDescription) === -1) {
                                 lineStates.push(s.statusSeverityDescription);
                             }
                         });
                         newLine['status'] = lineStates;
                     } else {
                         newLine['status'] = line.lineStatuses[0].statusSeverityDescription;
                     }

                     newLineStatus.push(newLine);
                 });

                 setLineStatuses(newLineStatus);
                 setLastUpdated(new Date());

                 localStorage.setItem('lineCache', JSON.stringify(newLineStatus));
                 localStorage.setItem('lastUpdated', new Date().toString());
             })
             .catch((error) => {
                 console.log(error);
             });
    }

    return (
        <div className="AppContainer">
            <div className="App">
                <header className="AppHeader">
                    <Grid container spacing={3} className="AppHeaderRow">
                        <Grid item xs={8} md={9} className="AppHeaderItem">
                            <h1 className="AppTitle">TfL Service Updates</h1>
                        </Grid>
                        <Grid item xs={4} md={3} className="AppHeaderItem AppHeaderClock">
                            <Clock />
                        </Grid>
                    </Grid>
                </header>

                <LineStatusTable statuses={lineStatuses} />
                <LastUpdated updated={lastUpdated} />
            </div>
        </div>
    );
}

export default App;
