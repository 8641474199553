import React from "react";

import Hidden from '@material-ui/core/Hidden';
import Grid from "@material-ui/core/Grid";
import "./assets/css/LineStatusTable.css";

function LineStatusTable(props) {
    const { statuses } = props;

    const statusOrder = [
        'Suspended',
        'Part Suspended',
        'Planned Closure',
        'Part Closure',
        'Severe Delays',
        'Reduced Service',
        'Bus Service',
        'Minor Delays',
        'Good Service',
    ];

    const orderedStatuses = [].concat(statuses).sort((a, b) => {
        let aStatus = statusOrder.indexOf((typeof(a['status']) === 'object' ? a['status'][0] : a['status']));
        let bStatus = statusOrder.indexOf((typeof(b['status']) === 'object' ? b['status'][0] : b['status']));

        if(aStatus === bStatus) {
            return 0;
        }
        return aStatus > bStatus ? 1 : -1;
    });

    const lineColourMap = {
        "Bakerloo": {
            backgroundColor: "#AE6118",
            color: "#FFFFFF",
        },
        "Central": {
            backgroundColor: "#E41F1F",
            color: "#FFFFFF",
        },
        "Circle": {
            backgroundColor: "#F8D42D",
            color: "#113892",
        },
        "District": {
            backgroundColor: "#007229",
            color: "#FFFFFF",
        },
        "DLR": {
            backgroundColor: "#00BBB4",
            color: "#FFFFFF",
        },
        "Hammersmith & City": {
            backgroundColor: "#E899A8",
            color: "#113892",
            name: "H'smith & City",
        },
        "Jubilee": {
            backgroundColor: "#686E72",
            color: "#FFFFFF",
        },
        "Metropolitan": {
            backgroundColor: "#893267",
            color: "#FFFFFF",
        },
        "Northern": {
            backgroundColor: "#000000",
            color: "#FFFFFF",
        },
        "Piccadilly": {
            backgroundColor: "#0450A1",
            color: "#FFFFFF",
        },
        "TfL Rail": {
            backgroundColor: "#0019A8",
            color: "#FFFFFF",
        },
        "Tram": {
            backgroundColor: "rgb(255, 255, 255)",
            color: "rgb(18, 52, 131)",
            borderRight: "1px solid rgb(112, 112, 112)",
        },
        "Victoria": {
            backgroundColor: "#009FE0",
            color: "#FFFFFF",
        },
        "Waterloo & City": {
            backgroundColor: "#70C3CE",
            color: "#113895"
        },
        "London Overground": {
            backgroundColor: "#F86C00",
            color: "#FFFFFF",
            name: "Overground",
        }
    };

    const getLineName = (line) => {
        if(typeof(lineColourMap[line.name]) !== 'undefined' && typeof(lineColourMap[line.name].name) !== 'undefined') {
            return lineColourMap[line.name].name;
        }
        return line.name;
    };

    const getLineStyle = (line) => {
        if(typeof(lineColourMap[line.name]) !== 'undefined') {
            return lineColourMap[line.name];
        }
        return {};
    };

    const getStatusStyle = (line) => {
        let statusId = statusOrder.indexOf(line['status']);

        let style = {};
        if(statusId < (statusOrder.length - 2)) {
            style.fontWeight = '700';
        }
        return style;
    }

    const getLineStatus = (line) => {
        if(typeof(line['status']) === 'object') {
            return (
                <>
                    {line['status'].join(', ')}
                </>
            )
        }
        return (
            <>
                {line['status']}
            </>
        )
    }

    const statusCount = orderedStatuses.length;
    const dividedStatuses = [];
    dividedStatuses.push(orderedStatuses.slice(0, Math.round(statusCount / 2)));
    dividedStatuses.push(orderedStatuses.slice(Math.round(statusCount / 2)));
    console.log(dividedStatuses); 

    return (
        <div>
            <Hidden lgUp>
                <Grid container>
                    {orderedStatuses.map((line, index) => {
                        return <Grid item key={index} xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={6} className="LineName" style={getLineStyle(line)}>{getLineName(line)}</Grid>
                                <Grid item xs={6} className="LineStatus" style={getStatusStyle(line)}>{getLineStatus(line)}</Grid>
                            </Grid>
                        </Grid>
                    })}
                </Grid>
            </Hidden>
            <Hidden mdDown>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        {dividedStatuses[0].map((line, index) => {
                            return <Grid item key={index} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6} className="LineName" style={getLineStyle(line)}>{getLineName(line)}</Grid>
                                    <Grid item xs={6} className="LineStatus" style={getStatusStyle(line)}>{getLineStatus(line)}</Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        {dividedStatuses[1].map((line, index) => {
                            return <Grid item key={index} xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6} className="LineName" style={getLineStyle(line)}>{getLineName(line)}</Grid>
                                    <Grid item xs={6} className="LineStatus" style={getStatusStyle(line)}>{getLineStatus(line)}</Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Hidden>
        </div>
    );
}

export default LineStatusTable;
