import React from "react";
import "./assets/css/Clock.css";

function Clock() {
    const locale = 'en';
    const [ time, setTime ] = React.useState(new Date());

    React.useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 5000);

        return () => {
            clearInterval(timer);
        }
    });

    return (
        <div className="AppClock">
            {time.toLocaleTimeString(locale, { hour: 'numeric', hour12: false, minute: 'numeric' }) }
        </div>
    )
};

export default Clock;
